<template>
  <div class="about">
    <div class="about__head">
      <div class="about__head--avatar">
        <img src="../assets/about/avatar.png" alt="">
      </div>
      <div class="about__head--info">
        <div>
          <img src="../assets/about/account.png" alt="Account">
          <div>{{ userinfo && userinfo.userNo }}</div>
        </div>
        <div>
          <img src="../assets/about/amount.png" alt="Amount">
          <div>{{ this.moneybab.amount }}</div>
        </div>
      </div>
      <div class="about__head--service" @click="gotoService">
        <img src="../assets/about/service.png" alt="Service">
        <span>客服中心</span>
      </div>
    </div>
    <div class="about__content">
      <div class="about__banner">
        <slider animation="fade" :control-btn="false" :height="bannerHeight">
          <slider-item v-for="(pic, index) in picList" :key="index">
            <img :src="pic.coverImg" alt="">
          </slider-item>
        </slider>
      </div>
      <div class="about__propaganda">
        <div class="about__propaganda--bg">
          <PaoMaDeng :delay="0.5" :speed="30" :content="msgList" class="--wrap">
            <span v-for="(item, index) in msgList" :key="index">
              {{ item.content }}
            </span>
          </PaoMaDeng>
        </div>
      </div>
      <div class="about__midhead">
        <div>
          <img :src="src" alt="">
        </div>
      </div>
      <div class="about__tables">
        <Spin v-if="tableLoading" fix class="demo-spin-col">
          <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
          <div>台桌加载中…</div>
        </Spin>
        <div v-if="noTable" class="about__tables--notable">暂无启用台桌 如有疑问请联系官方客服 谢谢！</div>
        <div v-else v-for="item in tableData" :key="item.title" class="carditem">
          <div class="carditem_head">
            <div class="carditem_head_item">
              <div>四名宝{{ padZero(item.tableId) }}</div>
              <div>{{ item.operator }}</div>
            </div>
            <div class="carditem_head_people">
              <img src="../assets/about/people.png"  alt="">
              <div>{{ item.players }}</div>
            </div>
          </div>
          <div :class="['roadmap', { '--close': item.stage < 0 }]" :style="roadMapStyle" @click="gotoTable(item)">
            <!-- <span v-if="item.stage < 0"  class="cardroad_close">洗牌中</span> -->
            <div v-for="item in roadData[item.tableId]" :key="item.index" class="cardroad_list">
              <div v-for="item1 in item" :key="item1.index" class="cardroad_item">
                <img
                  v-if="item1[1] == 0"
                  :src="require(`../assets/newImage/${item1[0]}.png`)"
                  alt=""
                />
                <div v-else>
                  
                  <img v-if="`${item1[0]}` == 1"
                    :src="time1"
                    alt=""
                  />
                  <img v-else-if="`${item1[0]}` == 2"
                    :src="time2"
                    alt=""
                  />
                  <img v-else-if="`${item1[0]}` == 3"
                    :src="time3"
                    alt=""
                  />
                  <img v-else-if="`${item1[0]}` == 4"
                    :src="time4"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="roadmap_state" v-if="item.countDown && item.countDown > 0" style="font-weight:800">{{ item.countDown }}</div>
            <div class="roadmap_state" v-else>{{ item.tablestate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="loading == true"
      v-load="true"
      load-background="rgba(0, 0, 0, .7)"
      load-type="cube-grid"
      style="height: 200vh; width: 100%; margin-top: -200%"
    />

    <Main-tab-bar />
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import MainTabBar from "../components/Tabbar/MainTabBar.vue"
import PaoMaDeng from "heyushuo-marquee"
import RedPacket from "../components/redPacket/index"
import classTree from "../components/tabbar-class-tree.vue"
import { isEmptyObject } from "../config/index"

export default {
  name: 'About',
  components: {
    MainTabBar,
    PaoMaDeng,
    RedPacket,
    [classTree.name]: classTree,
  },
  data() {
    return {
      userinfo: null,
      show: false,
      iwidth: "",
      iHeight: "",
      country: "台湾",
      loading: false,
      tableLoading: false,
      fGetBackFundpwd: false,
      imgPreview: "",
      imgNext: "",
      imgLast: "",
      scale: 0.3,
      translateX: -250,
      translateY: -100,
      tableData: null,
      limitConfig: {}, //限红设置
      currentInx: 0,
      realHeight: 0,
      timer: "",
      countTime: 0,
      roadData: [[]],
      loginmsg: this.$t("common.logintip"),
      memberlevel: "",
      canShow: false,
      uploadHeaders: { "x-auth-token": localStorage.getItem("TOKEN") },
      form: {
        transferPass: "",
      },
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      rules: {
        transferPass: {
          required: true,
          message: this.$t("general.inputTip"),
          trigger: "blur",
        },
      },
      dialogTitle: "资金密码",
      usernameS: "",
      user: {},
      choseItem: 0,
      accountValue: "1",
      status: 0,
      time1: require("../assets/newImage/11.png"), // 发送验证码倒计时
      time2: require("../assets/newImage/21.png"), // 发送验证码倒计时
      time3: require("../assets/newImage/31.png"), // 发送验证码倒计时
      time4: require("../assets/newImage/41.png"), // 发送验证码倒计时
      sendMsgDisabled1: false,
      sendMsgDisabled2: false,
      sendMsgDisabled3: false,
      sendMsgDisabled5: false,
      moneyList: {},
      coinType: "",
      balanceType: 0,
      sliderSellLimitPercent: 0,
      sliderSellMarketPercent: 120,
      currencyNum: "",
      gameUrl: "",
      hallUrl: "",
      isTg: false,
      transferNo: "",
      transferPass: "",
      disableBtn: false,
      tabBarValue: "1",
      countdown: 60,
      picShow: false,
      picList: [],
      msgList: [],
      moneybab: "",
      timercode: "",
      vLinks: {},
      betTimes: {},
      startRecPacket: false,
      modal: false,
      redData: {},
      activityObj: {},
      roadMapStyle: {},
      bannerHeight: "200px",
      noTable: false,
    }
  },

  methods: {
    preventDefault(e) {
      e.preventDefault()
    },
    disableScroll() {
      window.scrollTo(0, 0)
      document.body.addEventListener("touchmove", this.preventDefault, {
        passive: false,
      })
    },
    desRedPacket(val) {
      this.startRecPacket = false //红包组建销毁
      this.modal = true //唤醒弹窗
      this.redData = val
    },
    handleTabBarChange(value) {
      console.log("LyTabBar change:", value)

      if (value == "charge") {
        this.$router.push("/Recharge")
      } else if (value == "withdraw") {
        this.$router.push("/Withdraw")
      }
    },
    gotoService() {
      // window.location.herf = "https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t"

      // window.location.href="https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t";//当前标签页
      // window.open("https://tawk.to/chat/6327d98e54f06e12d8957824/1gd9qjl6t");//打开一个新的标签页

      this.$router.push("/customservice")
    },
    allGame() {
      this.currentInx = 0
    },
    siminbaoGame() {
      this.currentInx = 1
    },
    handleReachBottom() {},
    colseWC() {
      this.show = false
      this.$store.commit("setIsGoWC", true)
    },
    silderGo(silder, val) {
      this[silder] = val
    },
    tipFormat(val) {
      this.currencyNum = val
      return val
    },
    beforeUpload(data) {
      if (data && data.size >= 1024000 * 2) {
        this.$Message.error("上传图片大小不能超过2M")
        return false
      }
    },
    isPicNull(val) {
      if (val == null) {
        return true
      }

      return false
    },
    cancel() {
      this.loading = false
      this.$refs.form.resetFields()
    },
    track() {
      document.getElementById("baidu").style.display = "none"
      document.addEventListener("visibilitychange", this.checkBrowserChange)
    },
    checkBrowserChange() {
      if (document.hidden == false) {
        // this.getTime()
        this.countdown = 60
        clearInterval(this.timercode)
      } else {
        let that = this
        this.timercode = setInterval(() => {
          that.countdown--

          if (that.countdown <= 0) {
            clearInterval(that.timercode)
            that.$store.commit("setMember", null)
            localStorage.setItem("MEMBER", JSON.stringify(null))
            localStorage.setItem("TOKEN", null)
            localStorage.removeItem("USERKEY", null)
            that.$router.push({ path: "/" }).catch(() => {})
            that.countdown = 60
          }
        }, 1000)
      }
    },
    frontHandleSuccess(res, file, fileList) {
      this.$refs.upload1.fileList = [fileList[fileList.length - 1]]
      if (res.code == 0) {
        this.frontCardImg = this.imgPreview = res.data
      } else {
        this.$Message.error(res.message)
      }
    },
    backHandleSuccess(res, file, fileList) {
      this.$refs.upload2.fileList = [fileList[fileList.length - 1]]
      if (res.code == 0) {
        this.backCardImg = this.imgNext = res.data
      } else {
        this.$Message.error(res.message)
      }
    },
    handHandleSuccess(res, file, fileList) {
      this.$refs.upload3.fileList = [fileList[fileList.length - 1]]
      if (res.code == 0) {
        this.handCardImg = this.imgLast = res.data
      } else {
        this.$Message.error(res.message)
      }
    },
    noPhone() {
      this.$Message.info(this.$t("uc.safe.bindphonetip"))
      this.showItem(3)
    },
    showItemFundpwd() {
      this.fGetBackFundpwd = false
      this.handleReset("formValidate5")
      this.showItem(5)
    },
    renderPw() {
      this.$Modal.confirm({
        title: this.$t("uc.safe.resetfundpwd"),
        onOk: () => {
          this.$Message.info("Clicked ok")
        },
        render: (h) => {
          return h("Input", {
            props: {
              value: this.value,
              autofocus: true,
            },
            on: {
              input: (val) => {
                this.value = val
              },
            },
          })
        },
      })
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.submit(name)
        } else {
          this.$Message.error(this.$t("uc.safe.save_failure"))
        }
      })
    },
    handleReset(name) {
      this.$refs[name].resetFields()
    },
    showItem(index) {
      this.choseItem = index
    },
    goToWC() {
      var self = this

      if (this.$store.getters.isNobody == true) {
        this.$router.push({
          path: "/GamePage",
          query: { gpage: this.gameUrl, gid: "100" },
        })
        return
      }

      var params = {}
      const element = this.moneyList[1]

      this.balanceType = element.amount

      params["score"] = Number(this.balanceType)
      params["ccy"] = element.ccy

      this.$http
        .post(self.host + this.api.uc.myInnovationMinings, params)
        .then((response) => {
          var resp = response.body
          if (resp.code == "0000") {
            self.getGameUrl(0)
            // this.$router.push({ path: '/GamePage', query: { gpage: this.gameUrl } })
          } else {
            // self.$Message.error(resp.mesg);
            this.$Notice.error({
              title: this.$t("common.tip"),
              desc: resp.mesg,
            })
            // this.$Message.error(this.$t('common.logintip'));
          }
        })
    },
    goToGame() {
      // if (this.$store.getters.isNobody == true) {
      //   this.$router.push({ path: '/GamePage', query: { gpage: this.gameUrl , gid: '80' } })
      //   return
      // }
      this.$router.push({ path: "/GamePage" })
    },
    gotoHall() {
      var self = this

      if (this.$store.getters.isNobody == true) {
        this.$router.push({
          path: "/GamePage",
          query: { gpage: this.hallUrl, gid: "0" },
        })
        return
      }

      var params = {}
      const element = this.moneyList[1]

      this.balanceType = element.amount

      // params['score'] = Number(this.balanceType)
      params["ccy"] = element.ccy

      this.$http
        .post(self.host + this.api.uc.myInnovationMinings, params)
        .then((response) => {
          var resp = response.body
          if (resp.code == "0000") {
            self.getGameUrl(2)
            // this.$router.push({ path: '/GamePage', query: { gpage: this.hallUrl } })
          } else {
            // self.$Message.error(resp.mesg);
            this.$Notice.error({
              title: this.$t("common.tip"),
              desc: resp.mesg,
            })
            // this.$Message.error(this.$t('common.logintip'));
          }
        })
    },
    wcShow() {
      this.show = true
    },
    gotocharge() {
      this.$router.replace("/Recharge")
    },
    gotoWithdraw() {
      this.$router.replace("/Withdraw")
    },
    gotoLogin() {
      this.$router.replace("/Home")
    },
    gotoReg() {
      this.$router.replace("/MobileRegister")
    },
    getGameUrl(type) {
      var self = this
      let gameid = 0
      //  switch (type) {
      //   case 0:
      //     gameid = '100';
      //     break;
      //  case 1:
      //   gameid = '80';
      //   break
      // case 2:
      //   gameid = '0'
      //   break
      //   default:
      //     break;
      //  }

      let param = {}
      param.gameId = "" + type
      this.$http
        .post(this.host + this.api.uc.myInnovationLocked, param)
        .then((response) => {
          var resp = response.body
          if (resp.code == "0000") {
            // self.user = resp.data;
            // self.usernameS = this.user.username.slice(0,1);
            self.gameUrl = resp.data
            console.log("sdfsj" + self.gameUrl)
            const myArray = self.gameUrl.split("&")
            self.hallUrl = myArray[0] + "&" + myArray[2]
            // console.log("sdfsj11111" + self.hallUrl)

            this.$router.push({
              path: "/GamePage",
              query: { gpage: self.gameUrl, gid: gameid },
            })
          } else {
            // self.$Message.error(resp.mesg);
            this.$Notice.error({
              title: this.$t("common.tip"),
              desc: resp.mesg,
            })
            // this.$Message.error(this.$t('common.logintip'));
          }
        })
    },
    getmarquee() {
      var self = this
      this.$http.post(self.host + this.api.uc.toppromotion).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          self.msgList = resp.data
        } else {
          // self.$Message.error(resp.mesg);
          this.$Notice.error({
            title: this.$t("common.tip"),
            desc: resp.mesg,
          })
        }
      })
    },
    getMember() {
      //获取个人安全信息
      let self = this
      this.loading = true
      this.$http
        .post(self.host + this.api.uc.personalWallet)
        .then((response) => {
          let resp = response.body
          self.loading = false
          if (resp.code == "0000") {
            // self.user = resp.data;
            // self.usernameS = this.user.username.slice(0,1);
            self.moneyList = resp.data
            self.moneyList.forEach((element) => {
              if (element.ccy == "USDT") {
                self.moneybab = element
              }
            })
            // if (this.isTg) {
            //   this.changeCoin()
            //   this.sliderSellMarketPercent = Number(this.$store.getters.amount)
            // }
          } else {
            // self.$Message.error(resp.mesg);
            this.$Notice.error({
              title: this.$t("common.tip"),
              desc: resp.mesg,
            })
            // this.$Message.error(this.$t('common.logintip'));
          }
        })
    },
    changeCoin(value) {
      console.log(value)
      this.sliderSellMarketPercent = 0
      for (let index = 0; index < this.moneyList.length; index++) {
        const element = this.moneyList[index]
        if (element.ccy == this.coinType) {
          this.balanceType = element.amount
        }
      }
    },
    // caculRealHeight() {
    //   const windowHeight = document.documentElement.clientHeight
    //   // this.realHeight = Number(windowHeight) * (1 - 200 / windowHeight)
    //   this.realHeight = Number(windowHeight) * 0.68 - 60 // marginTop: 32vh, footer: 60px
    //   console.log("sdfs::::" + this.realHeight)
    // },
    getMemberInfo() {
      //获取个人安全信息
      var self = this

      this.$http.post(self.host + this.api.uc.memberInfo).then((response) => {
        var resp = response.body
        if (resp.code == "0000") {
          localStorage.setItem("MEMBER", null)
          self.$store.commit("setMember", resp.data)
          self.userinfo = resp.data
        } else {
          self.$Message.error(resp.mesg)
        }
      })
    },
    handleTableData(tables) {
      for (const i in tables) {
        let element = tables[i]
        element.players = element.users.length
        //    element.avatar = require('@/assets/newImage/1.png')
        this.getGameConfig(element)
        switch (element.stage) {
          case -1:
            element.tablestate = "台桌关闭"
            break
          case 0:
            element.tablestate = "台桌开始"
            break
          case 1:
            element.tablestate = "开始下注"
            break
          case 2:
            element.tablestate = "结束下注"
            break
          case 3:
            element.tablestate = "结算"
            break
          default:
            break
        }
        this.roadData[element.tableId] = this.handleRoadData(
          element.historyPeriod
        )
        console.log("sdfs")
      }
    },
    handleTableData1(tables) {
      for (const i in tables) {
        let element = tables[i]
        element.players = element.users.length
        //    element.avatar = require('@/assets/newImage/1.png')
        switch (element.stage) {
          case -1:
            element.tablestate = "台桌关闭"
            break
          case 0:
            element.tablestate = "台桌开始"
            break
          case 1:
            element.tablestate = "开始下注"
            break
          case 2:
            element.tablestate = "结束下注"
            break
          case 3:
            element.tablestate = "结算"
            break
          default:
            break
        }

        this.roadData[element.tableId] = this.handleRoadData(element.historyPeriod)
      }
    },
    handleRoadData(road) {
      const tempRoad = []

      Object.values(road).forEach((value) => {
        const [lastValue] = tempRoad.slice(-1)

        if (!lastValue || lastValue.length <= 0) {
          tempRoad.push([[value.result, 0]])
        } else if (lastValue.length >= 6) {
          const [lastElement] = lastValue.slice(-1)

          if (
            (Number(lastElement[0]) % 2 == 0 &&
              Number(value.result) % 2 == 0) ||
            (Number(lastElement[0]) % 2 == 1 && Number(value.result) % 2 == 1)
          ) {
            tempRoad.push([[value.result, 1]])
            lastValue.forEach((element) => {
              element[1] = 1
            })
          } else {
            tempRoad.push([[value.result, 0]])
          }
        } else {
          const [lastElement] = lastValue.slice(-1)

          if (lastElement !== undefined && lastElement !== null) {
            if (
              (Number(lastElement[0]) % 2 === 0 &&
                Number(value.result) % 2 === 0) ||
              (Number(lastElement[0]) % 2 === 1 &&
                Number(value.result) % 2 === 1)
            ) {
              lastValue.push([value.result, lastElement[1]])
            } else {
              tempRoad.push([[value.result, 0]])
            }
          } else {
            console.error("!!!!!!!!!!")
          }
        }
      })

      return tempRoad.slice(-16)
    },
    getGameConfig(element) {
      //获取游戏配置
      if (!element) {
        return
      }

      let that = this
      let param = {}
      param.tableId = element.tableId
      this.$http
        .get(this.host + this.api.game.getConfig, { params: param })
        .then((response) => {
          let resp = response.body
          // that.tableLoading = false
          if (!response.ok) {
            that.$Message.error("获取游戏配置异常")
          } else {
            element.limitBet = ""
            element.limitRed = ""
            element.videoLink = resp.videoLink
            element.betTime = resp.betTime
            that.betTimes[element.tableId] = element.betTime
            that.vLinks[element.tableId] = element.videoLink
            
            if (that.timer) {
      clearInterval(that.timer)
    }

    that.timer = setInterval(that.getTables1, 1000)
            this.tableLoading = false
          }
        })

      console.log("ssss" + element)
    },
    gotoTable({ tableId, operator, currentPeriod, stage}) {
      if (stage < 0) {
        return
      }
      if (!this.vLinks[tableId]) {
        this.$Message.error("获取游戏配置异常")
        return
      }
      this.$router.push({
        path: "/gameTable",
        query: {
          gpage: tableId,
          page1:operator,
          pp: this.vLinks[tableId],
          pp1: this.betTimes[tableId],
          pp2: currentPeriod.period,
        },
      })
    },
    getTables() {
      //获取桌子
      let that = this
      that.tableLoading = true

      this.$http.get(this.host + this.api.game.getTables).then((response) => {
        const resp = response.body
        if (resp.code == 200) {
          that.tableData = resp.data

         // 判斷是否有開啟的台桌
          if (isEmptyObject(that.tableData)) {
            that.tableLoading = false
            that.noTable = true
            return
          }

          that.noTable = false
          that.handleTableData(that.tableData)
        }
      })
    },
    getTables1() {
      let that = this
      this.$http.get(this.host + this.api.game.getTables).then((response) => {
        const resp = response.body
        // console.log(resp)
        if (resp.code == 200) {
          that.tableData = resp.data

          // 判斷是否有開啟的台桌
          if (isEmptyObject(that.tableData)) {
            that.tableLoading = false
            that.noTable = true
            return
          }

          that.noTable = false
          that.handleTableData1(that.tableData)
        }
      })
    },
    loadPicData() {
      let param = {}
      param.adType = "INDEX_BANNER"
      this.$http
        .get(this.host + this.api.uc.mycardlist, { params: param })
        .then((response) => {
          var result = response.body
          if (result.code == "0000") {
            this.picList = result.data
            this.picShow = true
          } else {
            this.picShow = false
          }
        })
    },
    padZero(num) {
      return num.toString().padStart(2, "0")
    },
    getElementSize() {
      const windowWidth = window.innerWidth
      const roadWidth = Math.min(windowWidth, 700) - 15
      this.bannerHeight =  `${Math.floor(windowWidth * 205 / 430)}px` // banner比例 430 * 205
      this.roadMapStyle = { height: `${Math.floor((roadWidth * 300) / 800)}px` } // 等比處理：roadmap背景圖size 800*300
      this.realHeight = Number(windowWidth) * 0.68 - 60 // marginTop: 32vh, footer: 60px
    },
  },
  computed: {
    ...mapGetters(['member', 'isLogin', 'lang']),
    sliderSellDisabled() {
      if (this.coinType !== "" && this.isTg != true) {
        return false
      }
      return true
    },
    src() {
      return require(`../assets/newImage/all${this.currentInx === 0 ? '' : '1'}.png`)
    },
  },
  created() {
    this.iwidth = document.documentElement.clientWidth
    this.iHeight = document.documentElement.clientHeight
    this.loadPicData()
    this.getmarquee()
    window.scrollTo(0, 0)

    let localtoken = localStorage.getItem("TOKEN")
    if (localtoken) {
      this.getMember()
      this.getMemberInfo()
    }

    this.getTables()
    this.getGameConfig()
    this.currentInx = 0

    // 監聽resize處理，暫拿掉
    // this.caculRealHeight()
  },

  mounted() {
   

    this.getElementSize()
    window.addEventListener('resize', this.getElementSize)
  },
  destroyed() {
    clearInterval(this.timercode)
    clearInterval(this.timer)
    this.timercode = null
    this.startRecPacket = false //防止点击到其他位置 离开销毁

    window.removeEventListener('resize', this.getElementSize)
  },
}
</script>

<style lang="scss">

.covef{
  position: absolute;
  background: url(../assets/newImage/66.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
}

.about {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  color: #fff;
  font-size: 14px;

	height: $full-page;

  background: url("../assets/bg.png") no-repeat;
  background-size: 100% 100%;
  background-position: center;

  &__head {
    background: url(../assets/newImage/1234.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0px;
    right: 0;
    left: 0;
    // width: calc(100% - 20px);
    height: 70px;
    // margin: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
    z-index: 100;
    width: 100%;
    &--avatar {
      img {
        width: 55px;
        height: 55px;
      }
    }

    &--info {
      width: 70%;
      margin: 0 10px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0 5px;
        padding-bottom: 2px;
        padding-top: 3px;
        font-weight: bold;
        border: 1px solid #353535;
        border-radius: 8px;
        background-color: #36343486;

        & + div {
          margin-top: 3px;
        }

        img {
          width: 16px;
          height: auto;
          margin-right: 8px;
        }
      }
    }

    &--service {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // padding: 5px;
      min-width: 60px;
      // font-weight: 500;
      border: 1px solid #353535;
      background-color: #21202086;
      border-radius: 8px;
      font-size: small;
      img {
        width: 26px;
        height: auto;
        margin-bottom: 1px;
      }
    }
  }

  &__content {
    height: calc(100vh - 90px);
    margin-top: 70px;
    padding-bottom: 60px;
    overflow-y: auto;
    background: transparent;
  }

  &__banner {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__propaganda {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4vh;
    border-top: 1px solid #515153;
    // background-color: #161616f9;
    // margin-top: 1vh;
    &--bg {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60%;
      padding: 0 3% 0 11%;
      color: #fff;
      box-sizing: border-box;
      background-image: url(../assets/netmage/lababack.png);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .wrap.--wrap {
        background: transparent;
        font-size: 12px;
      }
    }
  }

  &__midhead {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    padding: 0 20px;
    background: transparent;
    // background: url(../assets/newImage/game_btn_box_bg.jpg);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: 97% 100%;
    // border-top: 1px solid #515153;
    // box-shadow: 0 2px 6px 1px #171717;
    z-index: 1;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 30px;
      height: auto;
    }
  }

  &__tables {
    position: relative;
    min-height: 30%;
    background: transparent;

    &--notable {
      margin: 30% 0;
    }
  }
}
</style>
<style scoped lang="scss">
// /deep/ .vodal-dialog {
//   background: transparent;
//   margin-top: 150px;
//   width: 80%;

//   .vodal-close {
//     background-color: rgba(228, 180, 22, 0.2);
//     border-radius: 20px;
//     margin-right: 50px;
//     margin-top: 20px;
//     width: 30px;
//     height: 30px;
//     padding: 5px;
//   }
// }

// .blackback {
//   background: url(../assets/netmage/blackss.png);
//   width: 100%;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   margin-bottom: 10px;
// }

// .textbox {
//   display: flex;
//   justify-content: space-around;
//   color: #f4c46f;
//   font-size: medium;
//   font-weight: 600;
//   margin-left: -10px;
// }

// .propaganda_box {
//   width: 100%;
//   height: 4vh;
//   display: flex;
//   align-items: center;
//   border-top: 1px solid #515153;
//   background-color: #161616f9;
// }

// .paomadeng {
//   width: 100%;
//   height: 60%;
//   display: flex;
//   align-items: center;
//   color: #fff;
//   box-sizing: border-box;
//   padding: 0 3% 0 11%;

//   background-image: url(../assets/netmage/lababack.png);
//   background-position: 0 0;
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }

// .box1 {
//   // background: url(../assets/netmage/99.png);
//   background-color: #000;
//   width: 100%;
//   height: 30%;
//   //   margin-top: calc(iHeight / iwidth);

//   //   @media screen and (max-height:1920px) {
//   //   margin-top: 15vh;
//   // }

//   //  .account-item-in {
//   //   display: flex;
//   //   flex-direction: row;
//   //   justify-content: space-between;
//   //       margin-top: 30px;
//   //       padding: 1rem;
//   //       color: #fff !important;
//   //       font-size: medium;
//   // }

//   .account-item-in_1 {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     padding: 1rem;
//     color: #fff !important;
//     font-size: medium;
//   }
// }

// .box21 {
//   width: 100%;
//   height: 250px;
//   margin-left: 3%;
// }

// .select:focus {
//   border-color: greenyellow;
// }

// .safe {
//   background-color: #252627;
// }

button.ivu-btn {
  &:focus {
    box-shadow: 0 0 0 2px rgba(45, 140, 240, 0);
  }
}

button.ivu-btn.ivu-btn-primary {
  box-shadow: 0 0 0 2px rgba(45, 140, 240, 0);
}

// .nav-rights {
//   position: relative;
//   overflow: hidden;
// //   background: url(../assets/newImage/lobby_bg_m.jpg);
// //   background-position: center;
// //   background-repeat: no-repeat;
// //   background-size: 100% 100%;
//   height: $full-page;
// //   background-attachment: fixed;

//   .box222 {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     height: 180px;
//     z-index: 200;
//     // background-color: rgb(51, 49, 49);
//     // background: url(../assets/netmage/xxyz.png);
//     // background-position: center;
//     // background-repeat: no-repeat;
//     // background-size: cover;

//     .banner-panel {
//       height: 80%;
//       width: 100%;
//       // background-color: rgb(248, 248, 248);
//       // overflow: hidden;
//       position: relative;
//       margin-top: 0px;
//     }

//     .box_21 {
//       height: 60px;
//       padding: 10px;
//       align-items: center;
//       background-color: #000;
//       width: 100%;
//       display: flex;
//       justify-content: space-between;
//     }
//   }
// }

// .midhead {
//   height: 6vh;
//   // background: rgb(51, 49, 49);
//   background: url(../assets/newImage/game_btn_box_bg.jpg);
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 97% 100%;
//   padding-right: 10px;
//   z-index: 1;

//   border-top: 1px solid #515153;
//   box-shadow: 0 2px 6px 1px #171717;
// }

// .uploadimgtip {
//   position: relative;
//   top: -20px;
//   color: #f0a70a;
// }

.detail-list .input-control .ivu-input-group-prepend {
  width: 63px;
}

.detail-list .input-control .ivu-input {
  height: 45px;
}

.ivu-list-split .ivu-list-item {
  border-bottom: 0px solid #e8eaec;
}

// .buy-input .ivu-input {
//   color: #45b854;
//   font-weight: bold;
//   font-size: 22px;
//   height: 40px;
// }

// /deep/ .vsc-popup {
//   bottom: 100px;
// }

// .tips-g {
//   color: #8994a3;
//   font-size: 12px;
// }

// .gr {
//   color: #b4b4b4;
// }

// .fourgame {
//   width: 100%;
//   height: 60vh;
//   // margin-top: 20%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   padding-left: 5px;
//   padding-right: 5px;
// }

// .fourgame1 {
//   width: 100%;
//   height: 30vh;
//   display: flex;
//   flex-direction: column;
//   // justify-content: flex-end;
//   padding-left: 5px;
//   padding-right: 5px;
// }

// .user-right {
//   width: 80%;
// }

// .user .top-icon {
//   /* background: url("../../images/user/userplist.png") no-repeat 0 0; */
//   width: 75px;
//   height: 75px;
//   display: inline-block;
// }

// .user .top-icon.intro {
//   background-position: 0 -670px;
// }

// .user .user-info {
//   padding: 0px 10px;
//   background-color: #fff;
//   color: #fff;
// }

// .user .user-info .user-info-top {
//   border-bottom: 1px dashed #ebeff5;
//   padding-bottom: 20px;
// }

// .user .user-info h5 {
//   font-size: 18px;
// }

// .user .user-info h5 .iconfont {
//   font-size: 20px;
//   color: #e24a64;
//   margin-left: 10px;
// }

// .user-avatar {
//   display: flex;
//   justify-content: space-between;
// }

// .user-icons {
//   display: flex;
//   align-self: center;
//   width: 300px;
// }

// .midbody {
//   margin-top: 65%;
//   // display: flex;
//   // padding: 10px;
//   height: 60vh;
// }

// .user-icons .icons-in {
//   height: 45px;
//   width: 45px;
//   border-radius: 50%;
//   background-color: #00b5f6;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   align-self: center;
// }

// .user-icons .icons-in em {
//   color: white;
//   font-size: 20px;
//   font-style: normal;
// }



// .user-icons .user-name {
//   margin-left: 10px;
//   display: flex;
//   justify-content: flex-start;
//   /* align-items: center; */
//   flex-direction: column;
// }

// .user-icons .user-name span {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   width: 225px;
//   height: 52px;
//   overflow: hidden;
//   font-size: 14px;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   -o-text-overflow: ellipsis;
// }

// .user-top-icon .trade-info {
//   width: 420px;
//   padding-left: 30px;
//   display: flex;
// }

// .user-top-icon .trade-info .item {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// }

// .user-top-icon .trade-info .item.capital {
//   width: 60%;
// }

// .user-icons .user-name span.uid {
//   color: #8994a3;
//   font-size: 12px;
// }

// .circle-info {
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
//   height: 80px;
//   width: 80px;
//   border-radius: 50%;
//   border: 2px solid #ebeff5;
//   margin-left: 14px;
// }

// .horz {
//   display: flex;
//   justify-content: space-evenly;
//   margin-top: 60px;
//   padding: 5px;
// }

// .horz1 {
//   display: flex;
//   justify-content: space-evenly;
//   // margin-top: 5px;
//   padding: 5px;
// }

// .logowapper {
//   display: flex;
//   flex-direction: column;
//   height: auto;
//   align-items: center;
// }

// .headrow {
//   display: flex;
//   height: 20vh;
//   // align-items: center;
//   // align-items: baseline;
// }

// .bottomxx {
//   width: 80%;
//   margin-top: 90%;
// }

// .circle-info p.count {
//   color: #fff;
//   font-size: 14px;
//   font-weight: 600;
// }

// .user-avatar-public {
//   background: #df9a00;
//   border-radius: 50%;
//   height: 52px;
//   width: 52px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   box-shadow: 0 1px 5px 0 rgba(71, 78, 114, 0.24);
//   position: relative;
// }

// .siminbao {
//   background-image: url("../assets/newImage/1.png");
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
//   height: 200px;
//   width: 50%;
// }

// .user-avatar-public > .user-avatar-in {
//   background: #f0a70a;
//   border-radius: 50%;
//   height: 42px;
//   width: 42px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: white;
// }

// /*新加样式*/
// .router-link-active {
//   color: red;
// }

// /* router-link-exact-active router-link-active */

// .btn {
//   color: #f0a70a;
// }

// .ivu-btn-primary {
//   background-color: #f0a70a;
//   border-color: #f0a70a;
// }

// .disablestatus {
//   width: 100%;

//   color: #fff;
//   background-color: rgba(0, 0, 0, 0.4);
// }

.active {
  background-image: url(../assets/newImage/game_btn_on.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  // margin-top: 5px;
  // margin-left: 20px;
  // color: #f2f4f5;
  // display: flex;
  // align-items: center;
}

.unactive {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
}

.carditem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  // background-color: transparent;
  border: none;

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    padding: 0 10px 0 20%;
    color: #efe7e7;
    // background-color: #021427;
    background: url("../assets/about/table-head-bg.png") no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    // font-weight: 900;

    &_item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 1px 0;
      padding: 0 10px;

      & > div:first-child {
        color: #f5d989;
      }
    }

    &_people {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      img {
        width: 15px;
        height: auto;
        margin-right: 8px;
      }
    }
  }
}

.roadmap {
  position: relative;
  background: url(../assets/newImage/cardroad_bg_16.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  display: flex;

  &.--close {
    opacity: 0.5;
  }

  &_state {
    position: absolute;
    top:50%;
    left: 50%;
    width: 50%;
    color: #00000071;
    font-weight: 500;
    font-size: 2.2em;
    transform: translateY(-50%);
    z-index: 200;
  }
}

// .roadmap1 {
//   position: relative;
//   background: url(../assets/newImage/cardroad_bg_16.png);
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 100%;
//   display: flex;
//   opacity: 0.5;
// }

.cardroad_close {
  margin-top: 10%;
  margin-left: 20%;
  color: brown;
  font-weight: 600;
  font-size: xx-large;
}

.cardroad_list {
  width: 6.25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardroad_item {
  width: 95%;
  height: 17%;
  display: flex;
  // align-items: center;
  // justify-content: center;
  scale: 0.99;
  padding-top: 1px;
  padding-left: 1px;
  img {
    width: 100%;
    height: 90%;
  }
}

.ball-line {
  position: absolute;
  width: 2px;
  // height: 105%;
  top: 0;
  bottom: 0;
  right: 25%;
  background-color: #000;
  z-index: 100;
}

.demo-spin-col{
  position: absolute;
  top: 50%;
  left: 0;
  height: calc(100% - 35vh);
  background-color: rgba(0, 0, 0, .7);

  &.ivu-spin {
    color: #ccc;
  }
}

.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from { transform: rotate(0deg);}
  50%  { transform: rotate(180deg);}
  to   { transform: rotate(360deg);}
}

@media (max-width: 500px) {
  .carditem_head {
    height: 50px;
    font-size: 14px;
  }
}
</style>
