<template>
  <Tab-bar>
    <Tab-bar-item path="/about" activeColor="#bf8e42">
      <img slot="item-icon" src="../../assets/netmage/home_0.png" alt="" />
      <img slot="item-icon-active" src="../../assets/netmage/home_1.png" alt="" />
      <!-- <img slot="item-icon_1" src="../../assets/netmage/首页未点击.png" alt="" style="margin-top:0em;height: 15px;">
        <img slot="item-icon-active_1" src="../../assets/netmage/首页.png" alt="" style="margin-top:0em;height: 15px;"> -->
      <div slot="item-text">{{ $t("common.tabbar1") }}</div>
    </Tab-bar-item>
    <Tab-bar-item path="/Recharge" activeColor="#bf8e42">
      <img slot="item-icon" src="../../assets/netmage/deposit1.png" alt="" />
      <img slot="item-icon-active" src="../../assets/netmage/deposit.png" alt="" />
      <!-- <img slot="item-icon_1" src="../../assets/netmage/信息未点击.png" alt="" style="margin-top:0em;height: 15px;">
        <img slot="item-icon-active_1" src="../../assets/netmage/信息.png" alt="" style="margin-top:0em;height: 15px;"> -->
      <div slot="item-text">{{ $t("common.tabbar2") }}</div>
    </Tab-bar-item>
    <Tab-bar-item path="/Withdraw" activeColor="#bf8e42">
      <img slot="item-icon" src="../../assets/netmage/withdraw1.png" alt="" />
      <img slot="item-icon-active" src="../../assets/netmage/withdraw.png" alt="" />
      <!-- <img slot="item-icon_1" src="../../assets/netmage/客服未点击.png" alt="" style="margin-top:0em;height: 15px;">
        <img slot="item-icon-active_1" src="../../assets/netmage/客服.png" alt="" style="margin-top:0em;height: 15px;"> -->
      <div slot="item-text">{{ $t("common.tabbar3") }}</div>
    </Tab-bar-item>
    <Tab-bar-item path="/me" activeColor="#bf8e42">
      <img slot="item-icon" src="../../assets/netmage/asset_0.png" alt="" />
      <img slot="item-icon-active" src="../../assets/netmage/asset_1.png" alt="" />
      <!-- <img slot="item-icon_1" src="../../assets/netmage/账户未点击.png" alt="" style="margin-top:0em;height: 15px;">
        <img slot="item-icon-active_1" src="../../assets/netmage/账户.png" alt="" style="margin-top:0em;height: 15px;"> -->
      <div slot="item-text">{{ $t("common.tabbar4") }}</div>
    </Tab-bar-item>
  </Tab-bar>
</template>

<script>
import TabBar from "./TabBar"
import TabBarItem from "./TabBarItem"

export default {
  components: {
    TabBar,
    TabBarItem,
  },
}
</script>

<style></style>
