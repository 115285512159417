module.exports = {
    common: {
        login: '登錄',
        register: '註冊',
        loginregister: "登錄/註冊",
        logout: '退出登錄',
        tip: '溫馨提示',
        logintip: '請先登錄',
        expect: '敬請期待!',
        delete: '確定刪除嗎?',
        nodata: '暫無記錄',
        set: '設置',
        update: '修改',
        slogan: "全球前50的數字貨幣交易所",
        subslogan: "國家機構擔保資產安全",
        more: "更多",
        please: "請先",
        pleaseselect: "請選擇",
        searchplaceholder: "輸入幣種名稱搜索",
        searchplaceholderswap: "輸入合約名稱搜索",
        loading: "加載中...",
        close: "關閉",
        ok: "確定",
		fillComplete:"請填寫完整的信息",
        inGame: "進入遊戲",
        notice: "請使用金貝錢包賬戶登錄，如果你沒有金貝錢包賬戶，请點擊以下圖標下載金貝錢包APP",
        userNamEmpty: '金貝集團用戶',
        capitalPass: '資金密碼',
        capitalPassinput: '請輸入資金交易密碼',
        usertip:'付款成功',
        moneyback:'退還遊戲金幣',
        amount:"餘額",
        tibifirst:"詳情",
        message:"信息",
        service:'金貝集團客服',
        pleaseselectService: "請選擇存款方式",
        pleaseselectService1: '請選擇提款方式',
        pleaseselectService2: "请选择轉賬方式",
        tabbar1:'首頁',
        tabbar2:'存款',
        tabbar3:'取款',
        tabbar4:'賬戶',
        date: '請選擇時間段',
        date1: '總註冊人數',
        date2: '總輸贏',
        date3: '總投注量',
        date4: '會員ID',
        date5: '會員投注量',
        date6: '會員輸贏',
        date7: '總有效投注',
        date8: '會員有效投注',
        date9: '代理',
        game1: '實況百家樂',
        game2: '實況牛牛',
        game3: '實況龍虎',
        game4: '實況輪盤',
        game5: '實況扎金花',
        game6: '實況色碟',
        game7: '捕魚遊戲',
        game8: '棋牌遊戲',
        date10: '收款ID:',
        loginman: '使用已有賬號登錄',
        notice21: '尊貴的客戶，金貝客服竭誠為您服務',
        notice22: '7 * 24 online',
        notice23: '溫馨提示：目前玩家只能向代理賬號轉賬，請知悉 !!',
        male:'男',
        female: '女',
        exchange: '兑换',
        exchangeAll: '全部',

    },
    uc: {
        login: {
            noaccount: '沒有賬號？ ',
            loginman: '請先登錄',
            register: '註冊',
            login: '登 錄',
            welcomelogin: '歡迎登錄',
            usertip: '請輸入手機號',
            pwdtip: '請輸入密碼',
            validatecodeload: '正在加載驗證碼',
            validatemsg: '請先完成驗證',
            forget: '忘記密碼?',
            loginvalidate: '請輸入賬號',
            pwdvalidate1: '請輸入密碼',
            pwdvalidate2: '密碼長度不能少於6位',
            success: '登錄成功',
            goregister: "註 冊",
            getlostpwd: "忘記密碼",
            somebody: "游客試玩",
            nouser: "用戶不存在",
            normaluser:'對方用戶不支持轉賬',
        },
        finance: {
            center: '安全中心',
            personalassets: '充/提幣網絡',
            swapassets: '錢包地址',
            swapassets1: '賬號',
            billdetail: '交易記錄',
            recordetail: '存取款記錄',
            tradetail: '賬戶餘額',
            paydividends: '持币分红',
            invitingmining: '金額',
            charge: '存款',
            pickup: '取款',
            copy: '复制',
            copysuccess:'複製成功',
            copyfailed:'複製失敗',
            notice:"網絡服務費/手續費由會員支付",
            notice1:"每位會員都有獨立的USDT存款地址, 轉賬成功後系統會根據轉賬金額自動到賬, 如遇長時間未到賬請及時聯繫主頁客服進行咨詢",
            notice11: '如遇長時間未到賬請及時聯繫主頁客服進行咨詢',
            transfee:"手續費",
            timet:"時間",
            emailcodetip: '資金密码',
            gamedetail: '遊戲記錄',
            gametype:'遊戲類型',
            game0: '瓦力遊戲',
            game81: '視訊經典百家樂',
            game82: '視訊急速百家樂',
            game83: '視訊共咪百家樂',
            game84: '視訊競咪百家樂',
            game85: '視訊鬥牛',
            game86: '視訊扎金花',
            game87: '視訊牛牛',
            game88: '視訊龍虎',
            game89: '視訊篩寶',
            game90: '視訊輪盤',
            game91: '視訊色碟',
            game92: '視訊保險百家樂',
            game100: '瓦力體育',
            bet:'投注金額',
            personalInfo: '個人資料',
            personalInfo1: '姓名需與銀行卡持卡人姓名一致，否則無法提款',
            personalInfo2: '請選擇性別',
            personalInfo3: '添加生日，確保您已滿18歲',
            personalInfo4: '綁定手機號碼保護賬號安全',
            personalInfo5: '為了您的隱私安全，信息在確認後將無法修改，如需幫助，請',
            validBet: '有效投注金額',
            notice111:"聯繫客服",
            profit: '盈虧',
            addUaddress: '賬戶管理',
            addaddress:'添加提款信息',
            trans: '轉賬',
            agentdata: '代理數據',
            promotion: '代理推廣',
            promotion1: '代理推廣碼',
            promotion2: '保存二維碼',
            promotion3: '推廣鏈接',
            notice12:"提幣地址設置成功以後只能通過聯繫客服修改",
            notice123:'請輸入付款方信息' ,
            notice1234:"操作成功，會及時處理，請稍等",
            notice12345:"請輸入有效金額",
            xx1 : '充值訂單',
            xx2 : '提現訂單',
            xx3 : '狀態',
            xx4 : '審核中',
            xx5 : '成功',
            xx6 : '失敗',
            xx7 : '充值人姓名',
            xx8: '充值賬號',
            xx9 : '提現人姓名',
            xx10 : '提現賬號',
            xx11 : '備註',
            xx12 : '賬單創建時間',
            xx13 : '用戶編號',
            xx14 : '沒有更多數據',
            xx15 : '會員轉賬',
            xx16 : 'Android APP Install',
            xx17 : '轉賬提現',
            xx18 : '轉賬充值',
            xx19 : 'U盾充值',
            xx20 : '手動充值',
            xx21 : 'U盾提現',
            xx22 : '手動提現',
            xx23 : '遊戲上分',
            xx24 : '遊戲下分',
            xx25 : '彩金',
            xx26 : '補分',
            xx27 : '扣分',
            xx28 : '註冊紅包',
            xx29 : '訂單號',
            xx30 : '交易時間',
            xx31 : '交易後餘額',
            xx32 : '遊戲返水',
        },
        regist: {
            hasaccount: '已有賬號？請點擊登錄',
            login: '登錄',
            username: '用戶名',
            country: '國家',
            smscode: '短信驗證碼',
            sendcode: '發送驗證碼',
            resendcode: '重新發送(',
            pwd: '登錄密碼',
            repwd: '確認密碼',
            confrimpwd: '確認密碼',
            agreement: '我已閱讀並同意',
            userprotocol: '用戶協議',
            regist: '註冊',
            teltip: '請輸入手機號',
            telerr: '手機號碼格式不正確,請重新輸入',
            emailtip: '請輸入郵箱',
            emailerr: '密碼不正確,請重新輸入',
            confirmpwdtip: '請輸入確認密碼',
            confirmpwderr: '兩次密碼輸入不一致!',
            telregist: '手機註冊',
            emailregist: '郵箱註冊',
            usernametip: '請輸入用戶名',
            usernamemsg: '用戶名長度不能少於3位,多於15位',
            countrytip: '請選擇國家',
            smscodetip: '請輸入短信驗證碼',
            pwdtip: '請輸入登錄密碼',
            pwdmsg: '密碼長度不能少於6位',
            telno: '手機號碼',
            email: '郵箱地址',
            
            agreementtip: '請點擊同意',
            modaltitle: '請校驗',
            promotion: '邀請碼(必填)',
			china: '中國',
            singapore: '新加坡',
            korea: '韓國',
            japan: '日本',
            thailand: '泰國',
            russia: '俄羅斯',
            uk: '英國',
            vietnam: '越南',
            india: '印度尼西亞',
            italy: '意大利',
            hk: '香港',
            malaysia: '馬來西亞',
            taiwan: '台灣',
            turkey: '土耳其',
            germany: '德國',
            france: '法國',
            spain: '西班牙',
            america:'美國',
            mexico:'墨西哥',
            cambodia: '柬埔寨',
            wenlai: '汶莱',
        },
        forget: {
            title: "找回密碼",
            hasaccount: '已有賬號？請點擊登錄',
            login: '登錄',
            sendcode: '發送驗證碼',
            newpwd: '請輸入新密碼',
            confirmpwd: '請再次確認密碼',
            confirmpwd11: '收款用戶ID',
            save: '提交',
            walletaddress21: '金額必須大於0',
            pwdvalidate1: '請輸入確認密碼',
            pwdvalidate2: '兩次密碼輸入不一致!',
            resettelpwd: '重置登錄密码',
            resetemailpwd: '重置資金密码',
            newpwdtip: '請輸入新密碼',
            pwdvalidate3: '密碼長度不能少於6位',
            telno: '手機號',
            smscode: '請輸入驗證碼',
            teltip: '請輸入手機號',
            smscodetip: '請輸入短信驗證碼',
            email: '郵箱',
            emailcode: '郵箱驗證碼',
            emailtip: '請輸入郵箱',
            emailcodetip: '請輸入資金密码',
            resetpwdsuccess: '重置密碼成功',
            smswarn: '請注意查收短信',
            addresstip: '請輸入提幣地址',
            emailcodetip1: '請設置資金密碼',
            emailcodetip11: '請設置提幣地址',
            walletaddress: '錢包地址',
            huioneaddress: '汇旺賬號',
            addresstip221: '请输入收款用戶汇旺账号',
            walletaddress1: '收款地址',
            walletaddress2: '實際到賬金額',
            userID: '賬戶ID',
            addresstip21: '请输入收款用戶ID',
            upload: '點擊上傳憑證',
            addresstip211: '用戶ID',
            addresstip31: '請輸入匯旺付款者姓名',
            addresstip41: '請輸入匯旺付款者賬號',
            addresstip311: '姓名',
            addresstip411: '電話',
            addresstip321:'請輸入姓名'
        },
    }
}
