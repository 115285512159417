<template>
    <div class="container">
    </div>
</template>

<script>
// 引入红包组件
import showPacket from './bonusItem.js'
// import {stringify} from "qs";

export default {
    props:{
        activityObj:{
            type:Object,
            default:function (){
                return{}
            }
        }
    },
    name: 'test',
    data() {
        return {
            // 倒计时
            time: 40,
            // 红包数量
            itemCount: 100,
            // 红包实例存储栈
            dropStack: [],
            // 分数记录
            point: 0,
            // 已经销毁的红包数量
            dropped: 0,
            // 游戏状态
            game: false
        }
    },
    // 进页面的时候，让页面高度固定为100vh并且不能滚动
    beforeCreate() {
        document.body.style.maxHeight = '100vh'
        document.body.style.overflow = 'hidden'
    },
    // 离开页面之前 恢复，避免不影响其它页面
    beforeDestroy() {
        document.body.style.maxHeight = 'unset'
        document.body.style.overflow = 'unset'
    },
    mounted() {
        // 创建红包DOM，并把每次函数执行返回的dom实例放到存储栈中
        const arr = []
        for (const i in this.itemStack) {
            const instance = showPacket()
            arr.push(instance)
            instance.show({
                // 遍历x轴，因为x轴是随机生成的，所以红包掉落的起始位置也是随机的
                xAxis: this.itemStack[i],
                // 红包掉落的速度
                speed: 10000,
                // 红包点击事件
                onClick: (e) => {
                    e.preventDefault()
                    // 每次点击，分数+1
                    this.point++
                    this.hideOtherInstance()
                    let Form = new FormData()
                    let activityId = this.activityObj.activityId
                    Form.append('activityId',[activityId])
                    this.$http.post(this.host + this.api.uc.receiveAward, Form,{
                            headers:{
                                'Content-Type':'application/x-www-form-urlencoded'
                            }
                        }).then(res => {
                        let item = res.body.data.filter(item => item.activityType == 1)[0]
                        this.$emit('desRedPacket',item)
                    })
                },
                // 红包销毁事件
                onDropped: () => {
                    // 销毁数量加1
                    ++this.dropped
                    // 如果销毁数量等于红包数量，那么游戏停止
                    if (this.dropped === this.itemCount) {
                        this.game = true
                    }
                }
            })
        }
        // 将创建的红包实例存入栈中
        this.dropStack = arr
        this.start()
    },
    methods: {
        // 红包雨开始
        start() {
            this.game = false
            // 遍历红包栈，并根据事件平均分配掉落的时机
            for (const i in this.dropStack) {
                setTimeout(() => {
                    this.dropStack[i].start()

                    // 假设红包雨持续的事件是10秒，红包数量为20个，那么每个红包掉落的时机就是
                    // 当前遍历的索引 * （10秒 * 1000毫秒 / 红包数量）
                    // 等于每 i * 10000毫秒 / 20 掉落一个。
                    // 这里不懂的可以问我
                }, i * (this.time * 1000 / this.itemCount))
            }
        },
        hideOtherInstance(i) {
            this.dropStack.forEach((instance, index) => {
                if (i * 1 !== index) {
                    instance.hideCom()
                }
            })
        },
    },
    computed: {
        // 按照红包数量生成对应的X轴随机数
        itemStack() {
            const arr = []
            for (let i = 0; i < this.itemCount; i++) {
                // 保证红包的x轴在固定的范围内，根据实际需求控制
                arr.push(Math.floor(Math.random() * document.documentElement.clientWidth))
            }
            return arr
        }
    }
}

</script>

<style scoped lang="scss">
.container {
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: hidden;
    color: #000;
    font-size: 0.2rem;
  
}
</style>