module.exports = {
    common: {
        login: '登录',
        register: '注册',
        loginregister: "登录/注册",
        logout: '退出登录',
        tip: '温馨提示',
        logintip: '请先登录',
        expect: '敬请期待!',
        delete: '确定删除吗?',
        nodata: '暂无记录',
        set: '设置',
        update: '修改',
        slogan: "全球前50的数字货币交易所",
        subslogan: "国家机构担保资产安全",
        more: "更多",
        amount:"余额",
        please: "请先",
        pleaseselect: "请选择",
        searchplaceholder: "输入币种名称搜索",
        searchplaceholderswap: "输入合约名称搜索",
        loading: "加载中...",
        close: "关闭",
        ok: "确定",
		fillComplete:"请填写完整的信息",
        inGame: "进入游戏",
        notice: "温馨提示：请用金贝钱包账号登录，如果您没有金贝钱包账号，请点击以下图标下载金贝钱包APP",
        notice1: "请使用账号相同的银行卡进行付款，若姓名不一致，导致付款不到账，本公司不负责。存款遇到问题？请",
        userNamEmpty: '金贝集团用户',
        capitalPass: '资金密码',
        capitalPassinput: '请输入资金交易密码',
        usertip:'付款成功',
        moneyback:'退还游戏金币',
        message1122:"请选择充值方式",
        tibifirst:"详情",
        message:"信息",
        service:'金贝集团客服',
        pleaseselectService: "请选择存款方式",
        pleaseselectService1: "请选择提款方式",
        pleaseselectService2: "请选择转账方式",
        tabbar1:'首页',
        tabbar2:'存款',
        tabbar3:'取款',
        tabbar4:'账户',
        date: '请选择时间段',
        date1: '总注册人数',
        date2: '总输赢',
        date3: '总投注量',
        date4: '会员ID',
        date5: '会员投注量',
        date6: '会员输赢',
        date7: '总有效投注',
        date8: '会员有效投注',
        date9: '代理',
        date10: '收款ID:',
        game1: '真人百家乐',
        game2: '真人牛牛',
        game3: '真人龙虎',
        game4: '真人轮盘',
        game5: '真人炸金花',
        game6: '真人色碟',
        game7: '捕鱼游戏',
        game8: '棋牌游戏',
        loginman: '使用已有账号登录',
        notice21: '尊贵的客户，金贝客服竭诚为您服务',
        notice22: '24小时online',
        notice23: '目前玩家只能向代理账户转账, 请知悉 !!',
        male:'男',
        female: '女',
        exchange: '兑汇',
        exchangeAll: '全部',

    },
    uc: {
        login: {
            noaccount: '没有账号？',
            register: '注册',
            login: '登 录',
            welcomelogin: '欢迎登录',
            usertip: '请输入手机号',
            pwdtip: '请输入密码',
            validatecodeload: '正在加载验证码',
            validatemsg: '请先完成验证',
            forget: '忘记密码?',
            loginvalidate: '请输入账号',
            pwdvalidate1: '请输入密码',
            pwdvalidate2: '密码长度不能少于6位',
            success: '登录成功',
            goregister: "注 册",
            getlostpwd: "忘记密码",
            somebody: "游客试玩",
            nouser: "用户不存在",
            normaluser:'对方账号不支持转账',
        },
        regist: {
            hasaccount: '已有账号？请点击登录',
            login: '登录',
            username: '用户名',
            country: '国家',
            smscode: '短信验证码',
            sendcode: '发送验证码',
            resendcode: '重新发送(',
            pwd: '登录密码',
            repwd: '确认密码',
            confrimpwd: '确认密码',
            agreement: '我已阅读并同意',
            userprotocol: '用户协议',
            regist: '注册',
            teltip: '请输入手机号',
            telerr: '手机号码格式不正确,请重新输入',
            emailtip: '请输入邮箱',
            emailerr: '密码不正确,请重新输入',
            confirmpwdtip: '请输入确认密码',
            confirmpwderr: '两次密码输入不一致!',
            telregist: '手机注册',
            emailregist: '邮箱注册',
            usernametip: '请输入用户名',
            usernamemsg: '用户名长度不能少于3位,多于15位',
            countrytip: '请选择国家',
            smscodetip: '请输入短信验证码',
            pwdtip: '请输入登录密码',
            pwdmsg: '密码长度不能少于6位',
            telno: '手机号码',
            email: '邮箱地址',
            agreementtip: '请点击同意',
            modaltitle: '请校验',
            promotion: '代理ID(非必填)',
			china: '中国',
            singapore: '新加坡',
            korea: '韩国',
            japan: '日本',
            thailand: '泰国',
            russia: '俄罗斯',
            uk: '英国',
            vietnam: '越南',
            india: '印度尼西亞',
            italy: '意大利',
            hk: '香港',
            malaysia: '马来西亚',
            taiwan: '台湾省',
            turkey: '土耳其',
            germany: '德国',
            france: '法国',
            spain: '西班牙',
            america:'美国',
            mexico:'墨西哥',
            cambodia: '柬埔寨',
            wenlai: '汶莱',
        },
        forget: {
            title: "找回密码",
            loginman: '请先登录',
            hasaccount: '已有账号？请点击登录',
            login: '登录',
            sendcode: '发送验证码',
            newpwd: '请输入新密码',
            confirmpwd: '请再次确认密码',
            confirmpwd11: '收款用户ID',
            save: '提交',
            pwdvalidate1: '请输入确认密码',
            pwdvalidate2: '两次密码输入不一致!',
            resettelpwd: '重置登录密码',
            resetemailpwd: '设置/重置资金密码',
            newpwdtip: '请输入新密码',
            pwdvalidate3: '密码长度不能少于6位',
            telno: '手机号',
            smscode: '请输入验证码',
            teltip: '请输入手机号',
            smscodetip: '请输入短信验证码',
            email: '邮箱',
            emailcode: '邮箱验证码',
            emailtip: '请输入邮箱',
            emailcodetip: '请输入资金密码',
            resetpwdsuccess: '重置密码成功',
            smswarn: '请注意查收短信',
            addresstip: '请输入提币地址',
            addresstip21: '请输入收款用戶ID',
            addresstip221: '请输入汇旺账号',
            addresstip421: '请输入银行卡号',
            emailcodetip1: '请设置资金密码',
            emailcodetip11: '请设置提币地址',
            walletaddress: '钱包地址',
            huioneaddress: '汇旺账号',
            bankaddress: '银行卡号',
            walletaddress1: '收款地址',
            walletaddress2: '实际到账金额',
            walletaddress21: '金额必须大于0',
            userID: '賬戶ID',
            upload: '点击上传凭证',
            addresstip211: '用戶ID',
            addresstip31: '请输入汇旺付款者姓名',
            addresstip41: '请输入汇旺付款者账号',
            addresstip312: '请输入付款者姓名',
            addresstip412: '请输入付款者账号',
            addresstip3121: '请输入银行名',
            addresstip311: '姓名',
            addresstip411: '电话',
            addresstip321:'请输入姓名',
            addresstip3112:'所属银行',
            addresstip3213:'银行卡开户地址',
            addresstip3114: '持卡人姓名',
            addresstip3215:'所属银行名',
        },
        finance: {
            center: '安全中心',
            notice34: '1USD/USDT == 1游戏币',
            notice35: '一次转账金额1000-10000',
            personalassets: '充/提币网络',
            swapassets: '钱包地址',
            swapassets1: '账号',
            billdetail: '交易记录',
            recordetail: '存取款记录',
            tradetail: '账户余额',
            paydividends: '持币分红',
            invitingmining: '金额',
            charge: '存款',
            pickup: '取款',
            copy: '复制',
            copysuccess:'复制成功',
            copyfailed:'复制失败',
            notice:"网络服务费/手续费由会员支付",
            notice1:"每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: '如遇长时间未到账请及时',
            notice1111: '如果还未绑定银行卡，请先',
            notice11111: '绑定银行卡',
            transfee:"手续费",
            timet:"时间",
            emailcodetip: '资金密码',
            gamedetail: '游戏记录',
            gametype:'游戏类型',
            game0: '瓦力游戏',
            game81: '视讯经典百家乐',
            game82: '视讯急速百家乐',
            game83: '视讯共咪百家乐',
            game84: '视讯竞咪百家乐',
            game85: '视讯斗牛',
            game86: '视讯扎金花',
            game87: '视讯牛牛',
            game88: '视讯龙虎',
            game89: '视讯筛宝',
            game90: '视讯轮盘',
            game91: '视讯色碟',
            game92: '视讯保险百家乐',
            game100: '瓦力体育',
            bet:'投注金额',
            validBet: '有效投注金额',
            profit: '盈亏',
            addUaddress: '账户管理',
            addaddress:'添加提款信息',
            trans: '转账',
            agentdata: '代理数据',
            promotion: '代理推广',
            promotion1: '代理推广码',
            promotion2: '保存二维码',
            promotion3: '推广链接',
            personalInfo: '个人资料',
            personalInfo1: '姓名需与银行卡持卡人姓名一致，否则无法提款',
            personalInfo2: '请选择性别',
            personalInfo3: '添加生日，确保您已满18岁',
            personalInfo4: '绑定手机号码保护账号安全',
            personalInfo5: '为了您的隐私安全，信息在确认后将无法修改，如需帮助，请',
            personalInfo6: '请完善银行设置信息，所有输入都是必填项',
            notice12:"提币地址设置成功以后只能通过联系客服修改",
            notice32:"为了您的资金能迅速到账，请确保填写的姓名与银行卡的开户姓名一致",
            notice33:"信息提交成功以后，只能联系客服修改",
            notice111:"联系客服",
            notice123:"请输入付款方信息",
            notice1234:"操作成功，会及时处理，请稍等",
            notice12345:"请输入有效金额",
            notice134: "支付方式",
            xx1 : '充值订单',
            xx2 : '提现订单',
            xx3 : '状态',
            xx4 : '审核中',
            xx5 : '成功',
            xx6 : '失败',
            xx7 : '充值人姓名',
            xx8: '充值账号',
            xx9 : '提现人姓名',
            xx10 : '提现账号',
            xx11 : '备注',
            xx12 : '账单创建时间',
            xx13 : '用户编号',
            xx14 : '没有更多数据',
            xx15 : '会员转账',
            xx17 : '转账提现',
            xx18 : '转账充值',
            xx19 : '线上充值',
            xx20 : '手动充值',
            xx21 : 'U盾提现',
            xx22 : '手动提现',
            xx23 : '游戏上分',
            xx24 : '游戏下分',
            xx25 : '彩金',
            xx26 : '补分',
            xx27 : '扣分',
            xx28 : '注册红包',
            xx29 : '订单号',
            xx30 : '交易时间',
            xx31 : '交易后余额',
            xx32 :'游戏返水',
            xx33 :'尊敬的用户',
            xx34: '下线会员',
            xx35: '本月新注册会员',
            xx36: '本月活跃会员',
            xx37: '本月净输赢',
            xx38: '返佣比例',
            xx39: '本月佣金',
            xx40: '推广码',
            xx41: '专用域名',
            xx42: '下线会员列表',
            xx43: '佣金报表',
            xx44: '搜索会员账号',
            xx45: '会员账号',
            xx46: '最后登录时间',
            xx47: '注册时间',
            xx48: '本期佣金',
            xx49: '总输赢',
            xx50: '返点总计',
            xx51: '彩金',
            xx52: '金流折损费',
            xx53: '金流手续费',
            xx54: 'VIP额外佣金',
            xx55: 'VIP彩金',
            xx56: '佣金比例',
            xx57: '账单明细',
            xx58: '本期佣金',
            xx59: '平台费用',
            xx60: '收款账号',
            xx61: 'cam充值',
            xx62: 'cam提现',
            xx63 : '支付中',
            xx64 : '支付成功',
            xx65 : '支付失败',
            xx66 : '账单完成时间',
            xx67 : '充值方式',
            xx68 : '币种',
            xx69 : '提现方式',
            platform: '游戏平台',
            bitStatus: '注单状态',
            xx70 : '有效注单',
            xx71 : '赛事进行中',
            xx72 : '无效注单',
            xx73 : '开始时间',
            xx74 : '结束时间',
            xx75 : '注单号',
            xx76 : '单号',
            xx77 : '玩家ID',
            xx78 : '桌号',
            xx79 : '游戏结果',
            xx80 : '玩家单号',
            xx81 : '选择语言',
            xx82 : '欢迎来到金贝客服',
            xx83 : 'HI, 尊敬的客户',
            xx84 : '高棉语客服',
            xx85 : '越南语客服',
            xx86 : '中国语客服',
            xx87 : '英语客服',
            xx88 : '银行卡姓名',
            xx89 : '银行卡账号',
            xx91 : '银行名称',
            xx92 : '7x24 小时专线服务, 贴心至上',
            xx93 : '添加银行卡',
            xx94 : '添加提现地址',
            xx95 : '确定返回大厅吗？游戏中的钱如果没有正确返回，请在我的页面使用一键归账功能',
            xx96 : '联赛名称',
            xx97 : '主队',
            xx98 : '客队',
            xx99 : '比分',
            xx100 : '所有信息都是必填',
            xx101: '关于Jbbet',
            xx102: 'Jbbet是许多人喜欢的博彩娱乐目的地。这是当今顶级信誉良好和优质博彩网站中备受赞赏的游乐场。Jbbet不仅在东南亚享有盛誉,而且在整个亚洲市场博彩市场上也占有重要地位。',
            xx103: 'Jbbet的诞生',
            xx104: '尽管Jbbet最近很受欢迎,但它已经在亚洲市场经营多年。Jbbet成立于2005年,在柬埔寨正式运营。感谢PAGCOR在颁发运营许可证方面的合作。这家博彩公司有许多深受玩家喜爱的博彩类游戏.',
            xx105: 'Jbbet的产品和服务',
            xx106: '除了体育游戏和真人娱乐,我们还与许多主要娱乐公司合作。因此,始终确保提供最优质的娱乐博彩服务和产品。不仅带来了信誉良好且专业的博彩市场。还有一个多样化的游乐场,为每个人带来最好的选择。',
            xx107: '体育游戏',
            xx108: '最热门的投注产品。体育博彩领域，包括足球、篮球、排球、网球比赛,...非常迷人。玩家参与投注，观看世界上最大的赛季的激烈比赛。通过准确的赔率，有吸引力的回扣优惠满足您对投注的热情。',
            xx109: '真人娱乐',
            xx110: '与传统的赌场赌桌不同,今天我们也有真人娱乐场。在Jbbet 注册帐户后，玩家可以与辣妹一起加入真人娱乐场。您将通过直播屏幕与真人一起加入赌桌。不仅增加了吸引力和趣味性，还为玩家创造了更多的真实性和专业性',
            xx111: '老虎机游戏',
            xx112: 'Jbbet最吸引人的娱乐产品之一是老虎机游戏。我们提供来自全球主要游戏制造商的数百种老虎机。提供具有不同颜色,内容和奖品的多样化体验。',
            xx113: '棋牌游戏',
            xx114: '除了真人娱乐,Jbbet仍然提供具有醒目 3D 图形的赌博游戏。来自传统的棋牌游戏，游戏如龙虎、百家乐、二十一点,... ',
            xx115: '捕鱼游戏',
            xx116: '捕鱼是一个非常熟悉的游戏,既具有很高的娱乐性,又有助于赚取额外的钱。来到Jbbet,捕鱼变成了具有更多独特内容和图形的多样性。',
            xx117: 'Jbbet的优势',
            xx118: '拥有欧洲马耳他MGA颁发的合法执照。注册于英属维尔京群岛,是受国际认可的合法公司因此,在这里参与投注和赌场的玩家不必担心合法性。Jbbet配备了先进的技术设备,采用专业的安全系统。因此,玩家的所有信息都将是安全的，不会暴露在外部。如果有未经授权的第三方,我们也会及时发现并防止。另外我们始终拥有最专业的客户服务人员。确保 24/7 全天候支持并回答您的所有问题。这种优势也是我们在向国际友人介绍Jbbet时最有信心的。因此,在需要时,请联系客服人员以获取建议和答案。',
            xx119: '我们希望您能更好地了解我们。不要忘记。注册一个Jbbet 帐户,以便能够参与这个令人兴奋的投注游乐场',
            xx120: '不支持此币种,请去钱包页面兑汇或者切换货币',
            xx121: '付款人姓名',
            xx122: 'USDT地址(TRC20)',
            xx123: '地址管理',
            xx124: '全部',
            xx125: '充值',
            xx126: '提现',
            xx127: '取消',
            xx128: '申请中',
            xx129: '已确认',
            xx130: '已驳回',
            xx131: '体育记录',
            xx132: '游戏记录',
            xx133: '彩票',
            xx134: '已结',
            xx135: '未结',
            xx136: '取消',
            xx137: '昨日',
            xx138: '今日',
            xx139: '近7日',
            xx140: '近30日',
            xx141: '单注',
            xx142: '串场',
            xx143: '冠军',
            xx144: '自选日期',
            xx145 : '英语客服',
            xx146 : '隐藏',
            xx147 : '测试充值',
            xx148 : '游戏下注',
            xx149 : 'CMD体育',
            xx150 : 'Saba体育',
            xx151 : '乐游',
            xx152 : '比赛日期',
            xx153 : 'USDT 兑出',
            xx154 : 'USDT 兑入',
            xx155 : '泰国语客服',
            xx156 : '印尼语客服',
            xx157 : 'JDB 老虎机',
            xx158 : 'PG 老虎机',
            xx159 : 'PP 老虎机',
            xx160 : 'NT 老虎机',
            xx161 : 'JDB 捕鱼',
            xx162 : 'HI, 欢迎您的到来',
            xx163 : '没有账户？',
            xx164 : '我的货币',
            xx165 : '兑换',
            xx166 : '注册红包',
            xx167 : 'CMD系统调账',
            xx168 : '游戏买断',
            xx169 : '游戏买入',
            xx170 : '累计奖金',
            xx171 : '奖金',
            xx172 : '取消投注',
            xx173 : '回滚投注',
            xx174 : '游戏结算',
            xx175 : '游戏下注',
            xx176 : '提现失败退还',
            xx177 : '会员转账',
            xx178 : '扣分',
            xx179 : '返水',
            xx180 : '补分',
            xx181 : '彩金',
            xx182 : '三方',
            xx183 : '汇旺',
            xx184 : 'uDUN',
            xx185 : '手动',
            xx186 : '手动提现',
            xx187 : '汇旺',
            xx188 : 'U盾',
            xx189 : '请选择银行',
            xx190 : '从：',
            xx191 : '至：',
            xx192 : '汇率：',
            xx193 : '赢',
            xx194 : '赢',
            xx195 : '输',
            xx196 : '输',
            xx197 : '平',
            xx198 : '未结算',
            xx199 : '兑换金额不能低于1',
            xx200 : 'FB账号',
            xx201 : '输入密码',
            xx202 : '提现密码',
            xx203 : '返回',
            xx204 : '保存',

        },
    }
}
