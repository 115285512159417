// 日期格式 - => /
export function formatTime(time) {
  if (!time) return
  return time.replace(/-/g, '/')
}
// 是否为空
export function isEmpty(val) {
  if (val === null || val === undefined || val === '') {
    return true
  } else {
    return false
  }
}
// 是否為空物件
export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0
}
// 金额格式化
export function formatNumber(str) {
  if (str === null || str === undefined || str === '') {
    return '--'
  } else {
    return (str + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return null
}

export function browser() {
  const u = navigator.userAgent
  return {
    edge: u.indexOf('Edge') > -1, // Edge内核
    trident: u.indexOf('Trident') > -1, // IE内核
    presto: u.indexOf('Presto') > -1, // opera内核
    webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, // android终端
    iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
    iPad: u.indexOf('iPad') > -1, // 是否iPad
    webApp: u.indexOf('Safari') === -1, // 是否web应用程序，没有头部与底部
    weixin: u.indexOf('MicroMessenger') > -1, // 是否微信 （2015-01-22新增）
    qq: u.match(/\sQQ/i) === 'qq', // 是否QQ
  }
}

// 防抖(非立即执行版)
export function debounce(func, wait) {
  var timeout

  return function () {
    var context = this
    var args = arguments

    clearTimeout(timeout)
    timeout = setTimeout(function () {
      func.apply(context, args)
    }, wait)
  }
}
// 防抖(立即执行版)
export function OnceDebounce(func, wait) {
  let timeout
  return function () {
    let context = this
    let args = arguments

    if (timeout) clearTimeout(timeout)

    let callNow = !timeout
    timeout = setTimeout(() => {
      timeout = null
    }, wait)

    if (callNow) func.apply(context, args)
  }
}
export function transDate(date, arr = ['今天', '昨天', '前天']) {
  var tt = new Date(parseInt(date))
  var days = parseInt((new Date().getTime() - date) / 86400000)
  var today = new Date().getDate()
  var year = tt.getFullYear()
  var mouth = tt.getMonth() + 1
  var day = tt.getDate()
  var time = tt.getHours() < 10 ? '0' + tt.getHours() : tt.getHours()
  var min = tt.getMinutes() < 10 ? '0' + tt.getMinutes() : tt.getMinutes()
  var result, offset
  offset = Math.abs(today - day)
  if (days < 3 && offset < 3) {
    if (offset === 0) {
      result = arr[0] + time + ':' + min
    } else if (offset === 1) {
      result = arr[1] + time + ':' + min
    } else if (offset === 2) {
      result = arr[2] + time + ':' + min
    }
  } else {
    result = year + '/' + mouth + '/' + day + ' ' + time + ':' + min
  }
  return result
}

// 给字符的最后一项替换为*号
export function encryptLastStr(str) {
  str = str.trim()
  if (!str) return ''
  if (str.length === 1) return str
  return str.replace(/(.*)(.)$/, '$1*')
}
