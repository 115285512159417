import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import VueClipboard from 'vue-clipboard2';
import VueRouter from 'vue-router';
import vueResource from 'vue-resource';
import VueI18n from 'vue-i18n';
import Api from './config/api';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import locale from 'view-design/dist/locale/zh-CN';
// import Carousel3d from "vue-carousel-3d";
import VueMarquee from 'vue-marquee-component';
// import './registerServiceWorker'
// import dragBall from 'vue-drag-ball'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import EasySlider from "vue-easy-slider";
// import VueAnalytics from 'vue-analytics';
import moment from 'moment'
import Calendar from 'vue-mobile-calendar'
import './assets/scss/global.scss';
import '@/assets/scss/iconfont/iconfont.css';

import Vodal from 'vodal';
import "vodal/common.css";
import "vodal/rotate.css";
Vue.use(Calendar);

import Loading from "vue-easy-loading"
Vue.use(Loading);

// import VueInstaller from "vue-pwa-installer"
// import VueAliplayerV2 from "vue-aliplayer-v2";
// Vue.use(VueAliplayerV2)



// Vue.use(VueInstaller, { /* options */ })

Vue.use(VueClipboard);
Vue.use(VueRouter);
Vue.use(vueResource);
Vue.use(VueI18n);
Vue.use(ViewUI, { locale });
// Vue.use(Carousel3d);
Vue.use(VueMarquee);
// Vue.use(NProgress);
Vue.use(EasySlider);
// Vue.use(dragBall)

Vue.component(Vodal.name, Vodal);


// Vue.use(VueAnalytics, {
//   id: 'UA-238143558-1',
//   router
// })



Vue.prototype.rootHost = "https://sm.cham26.com/smapi";
Vue.prototype.host = "https://sm.cham26.com/smapi";


// 测试环境：http://154.207.104.9:9920  生产环境：http://154.207.104.13:9920

// https://sm.jb88.bet/smapi


Vue.prototype.api = Api;
Vue.http.options.credentials = false;
Vue.http.options.emulateJSON = false;
// Vue.http.options.headers = {
//     'Content-Type': 'application/json;charset=UTF-8',
//     // Content-Type: application/json;charset=utf-8
// };

Vue.http.options.headers = {
  // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  'Content-Type': 'application/json'
};

Vue.filter('datefomt', function (input, fomtstr) {
  return moment(input).format(fomtstr)
});

const i18n = new VueI18n({
  locale: 'zh_CN',
  messages: {
      'zh_CN': require('./assets/lang/cn.js'),
      'en_US': require('./assets/lang/en.js'),
      'zh_HK': require('./assets/lang/hk.js'),
  },
  silentTranslationWarn: true
});


Vue.http.interceptors.push((request, next) => {
  //登录成功后将后台返回的TOKEN在本地存下来,每次请求从sessionStorage中拿到存储的TOKEN值
  request.headers.set('Authorization', localStorage.getItem('TOKEN'));
  // NProgress.start()
  // request.headers.set('Content-Type' , 'application/json;');


let lang = localStorage.getItem('LANGUAGE');
// if(lang!=null){
//       lang = lang.substr(1);
//       lang = lang.substr(0,lang.length-1);
//   }
request.headers.set('lang', lang || "zh_CN");

  next((response) => {
      //登录极验证时需获取后台返回的TOKEN值
      // var xAuthToken = response.headers.get('x-auth-token');
      // if (xAuthToken != null && xAuthToken != '') {
      //     localStorage.setItem('TOKEN', xAuthToken);
      // }

      // NProgress.done()
      if (response.data.code == '1402' || response.data.code == '3000' || response.data.code == '401') {
          store.commit('setMember', null)
          localStorage.setItem("MEMBER", null)
          router.push('/')
          return
      }

      // token失效的不合法请求
      if (response.data.code == '400') {
        store.commit('setMember', null)
        localStorage.setItem("MEMBER", null)
        store.dispatch('toggleLoginAlert')
        return
      }

      return response
  });
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
