<template>
    <div class="boxRedPacket" ref="packet" @touchstart.once="handleClick" v-if="hidden">
    </div>
</template>

<script>
const { Animation, Clip } = require('chito')
export default {
    name: 'bonusRain',
    data() {
        return {
            // 代表红包该显示还是不显示
            hidden: true,
            // 用于存放chito生成的动画实例
            animation: null,
            // 用于存放从父组件传进来的配置项
            options: null,
            // 用于记录红包是不是已经掉落
            isDropped: false,
            // 用于判断红包在掉落的时候是顺时针旋转还是逆时针旋转
            rotateComputed: 1
        }
    },
    methods: {
        // getRedData(item){
        //     return
        // },
        hideCom() {
            this.hidden = false
        },
        // 点击事件
        handleClick(e) {
            // 请求数据
            this.$nextTick(() => {
                // 因为每个红包都只能点击一次，所以点击红包后，就让动画停止
                this.animation.stop()
                // 添加class 类
                // this.$refs.packet.className = 'boxopening'

                // 因为红包点击后也代表了销毁，所以在这里也要调用dropped事件
                if (this.isDropped === false) {
                    this.options.onDropped()
                    this.isDropped = true
                }

                // 自定义的click事件，让事件能够分发出去，因为不是直接通过dom的方式向
                // 父组件插入的组件，所以不能用$.emit分发事件
                if (this.hidden === true) {
                    this.options.onClick(e)
                }

                this.$refs.packet.style.transform = 'rotate(0deg) scale(3)'
                this.$refs.packet.style.background = 'url(https://m.ihengshuo.com//tyc/img/pc_red.png)'
                this.$refs.packet.style.backgroundSize = 'contain'
                this.$refs.packet.style.backgroundRepeat = 'no-repeat'
                this.$refs.packet.style.animation = 'unset'

                // 点击红包后修改红包样式
                setTimeout(() => {
                  
                    // this.hidden = false
                    // 修改完样式总不能直接消失,所以适当给一个延时
                    setTimeout(() => {
                        this.hidden = false
                    }, 500)
                }, 350)
            })
        },
        show(obj) {
            // 把传进来的配置项赋值给options
            this.options = obj

            // 自定义了一个beforShow的钩子，这样动画在初始化的时候想执行什么方法也方便
            if (this.options.beforeShow) {
                this.options.beforeShow()
            }

            // 如果穿进来了一个封面图片，就替换掉当前的红包封面
            if (obj.cover) {
                this.$refs.packet.style.background = `url(${obj.cover})`
                this.$refs.packet.style.backgroundSize = 'contain'
                this.$refs.packet.style.backgroundRepeat = 'no-repeat'
            }

            // 创建一个动画剪辑
            const clip = new Clip({
                // 剪辑持续的时间，如果掉落距离是固定的，那么掉落时间就决定了红包掉落的速度
                duration: obj.speed || 2000,
                // 剪辑重复一次，因为每个红包都是独立的
                repeat: 1
            }, {
                // 掉落的路线，从 -100开始到屏幕高度
                y: [-100, document.documentElement.clientHeight]
            })
            // 红包在掉落的过程中，每一次运动都会触发clip的update事件，
            // 如果需要一些效果，可以在这里定义
            clip.on('update', (ev) => {
                if(!this.hidden) return
                var keyframe = ev.keyframe
                // 要操作dom 要用nextTick
                this.$nextTick(() => {
                    // 掉落的过程中动态改变红包的y轴位置
                    this.$refs.packet.style.top = keyframe.y + 'px'
                    // 根据配置项的x轴位置来设置红包的x轴位置
                    this.$refs.packet.style.left = obj.xAxis + 'px'
                    // 红包掉落的时候让它旋转起来
                    this.$refs.packet.style.transform = 'rotate(' + (ev.progress * 180 * this.rotateComputed) + 'deg)'
                })
            })
            // 创建Animation实例
            this.animation = new Animation()
            // 把创建的剪辑添加到Animation实例中
            this.animation.addClip([clip])
        },
        start() {
            // rotateComputed给了数字1
            // 如果是1它就顺时针旋转，-1就是逆时针旋转
            this.rotateComputed = (Math.random() * 10) > 5 ? 1 : -1

            // 让动画开始播放
            this.animation.start()

            // 动画完成播放的事件
            this.animation.on('complete', () => {
                // 动画结束之后回调onDropped事件
                if (this.isDropped === false) {
                    this.options.onDropped()

                    // 动画完成后，把isDropped的值修改为是
                    // 代表红包已经掉落，理论上没什么用，但是玩意需要父组件做判断
                    this.isDropped = true
                }
                // 动画播放完成后，隐藏红包
                this.hidden = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
* {
    user-select: none;
    outline: none;
}

.boxRedPacket {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -10rem;
    background: url("../img/red.png") no-repeat;
    background-size: contain;
    transform: rotate(0deg);
    z-index: 1000;
}

box opening {
    animation: roundRule 0.3s ease-in-out infinite;
}

@-webkit-keyframes roundRule {
    0%,
    100% {
        -webkit-transform: rotate(-15deg);
    }
    50% {
        -webkit-transform: rotate(15deg);
    }
}
</style>